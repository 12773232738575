// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "TTD",
});

export const numberToCurrency = (price) => {
  //   return formatter.format(price);
  return formatter.format(price).replace(/(\.|,)00$/g, "");
};

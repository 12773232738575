import React from "react";
import styled from "styled-components";
import { colors } from "../colors/colors";

const SectionTitleContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 390px;
  padding: 45px 0;
  padding: ${(props) => props.margin && "5px 0"};
`;
const Subtitle = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 5px 0;
  line-height: 190%;
  color: ${colors.copy};

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
const Title = styled.h1`
  font-family: "Tanker";
  font-size: 50px;
  margin: 0;
  color: ${colors.copy};
  font-weight: 400;
  background-color: ${colors.copy};
  background-image: linear-gradient(45deg, #fb045d, #ff6c02);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  @media (max-width: 600px) {
    font-size: 40px;
  }
`;

const SectionTitle = (props) => (
  <SectionTitleContainer margin={props.margin}>
    <Subtitle>{props.subtitle}</Subtitle>
    <Title>{props.title}</Title>
  </SectionTitleContainer>
);

export default SectionTitle;

import styled from "styled-components";
import { graphql } from "gatsby";
import React, { useEffect } from "react";
import Layout from "../components/layout";
import ProductItem from "../components/product-item";
import SectionTitle from "../components/section-title";
import SEO from "../components/seo";
import { parseShopifyResponse, shopifyClient } from "../lib/shopify";

export default function ShopPage() {
  const [products, setProducts] = React.useState([]);

  useEffect(() => {
    shopifyClient.product
      .fetchAll()
      .then((res) => {
        console.log(parseShopifyResponse(res));
        setProducts(parseShopifyResponse(res));
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  return (
    <Layout>
      <SEO title="Browse our shop" />
      <SectionTitle
        title="Browse our shop"
        subtitle="Official Launch Coming Soon"
      />
      <ProductGrid>
        {products.map((product) => (
          <ProductItem product={product} />
        ))}
      </ProductGrid>
    </Layout>
  );
}

export const ProductGrid = styled.div`
  display: grid;
  max-width: 1100px;
  width: 100%;
  grid-gap: 20px;
  row-gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  margin: 0 auto;

  @media (max-width: 1100px) {
    padding: 0 20px;
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    grid-gap: 10px;
    row-gap: 30px;
  }
  @media (max-width: 360px) {
    grid-template-columns: repeat(2, minmax(130px, 1fr));
    grid-gap: 10px;
    row-gap: 30px;
  }
`;

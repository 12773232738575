import Client from "shopify-buy";
const domain = "yup-life.myshopify.com";
const storefrontAccessToken =
  process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN;

console.log({ domain, storefrontAccessToken });

export const shopifyClient = Client.buildClient({
  // storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  storefrontAccessToken: "a443ba6c560990d5bb2512cc2d6d581f",
  domain: domain,
});

export const parseShopifyResponse = (response) =>
  JSON.parse(JSON.stringify(response));

async function ShopifyData(query) {
  const URL = `https://${domain}/api/2021-07/graphql.json`;

  const options = {
    endpoint: URL,
    method: "POST",
    headers: {
      "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query }),
  };

  try {
    const data = await fetch(URL, options).then((response) => {
      return response.json();
    });

    return data;
  } catch (error) {
    console.log({ error });
    throw new Error("Products not fetched");
  }
}

export async function getAllProducts() {
  const query = `
  {
  products (first: 100) {
    edges {
      node {
        id
        title
        handle
        options {
            id
            name
            values
        }
        priceRange {
          minVariantPrice {
            amount
          }
        }
        variants(first: 20) {
          edges {
            node {
              id
            }
          }
        }
    
        images (first: 10) {
          edges {
            node {
              originalSrc
              altText
            }
          }
        }
      }
    }
  }
}
`;

  try {
    const response = await ShopifyData(query);

    const allProducts = response.data.products.edges
      ? response.data.products.edges
      : [];

    return allProducts;
  } catch (err) {
    console.log(err);
  }
  return [];
}

import React, { useContext } from "react";
import styled from "styled-components";
import { numberToCurrency } from "../lib/utils";
import { Badge } from "@mantine/core";
import { Link } from "gatsby";
import { colors } from "../colors/colors";

export default function ProductItem({
  product,
  onClick = undefined,
  selected = false,
}) {
  return (
    <div
      exit={{ opacity: 0, scale: 0.5, y: 50 }}
      transition={{ duration: 0.7, ease: [0.5, 1, 0.89, 1] }}
    >
      {/* <Link
        style={{ textDecoration: "none" }}
        key={product.id}
        to={`/shop/${product.handle}`}
      > */}
      <div
        initial={{ opacity: 0, y: 20 }}
        viewport={{ once: true, amount: "some" }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: [0.5, 1, 0.89, 1] }}
        onClick={() => onClick && onClick(product.title)}
        key={product.id}
      >
        <ImageContainer
          unavailable={product.availableForSale ? false : true}
          selected={selected}
        >
          <img
            quality={100}
            src={product?.images[0]?.src}
            fill
            alt={`${product.title} cover image`}
          />
        </ImageContainer>
        <Flex>
          <div>
            <Copy
              selected={selected}
              style={{
                marginBottom: 0,
              }}
            >
              {product?.title}
            </Copy>
            <Copy
              style={{
                opacity: 0.6,
                fontWeight: 500,
                marginTop: 4,
                marginBottom: 5,
              }}
            >
              {product?.productType}
            </Copy>
          </div>
          <Copy style={{ opacity: 0.9, fontWeight: 600, marginTop: 2 }}>
            {numberToCurrency(product?.variants[0]?.price?.amount)}
          </Copy>
        </Flex>
      </div>
      {/* </Link> */}
      {product.availableForSale ? [] : <Badge color="red">Out of Stock</Badge>}
    </div>
  );
}

const ImageContainer = styled.div`
  aspect-ratio: 9 / 14;
  cursor: pointer;
  width: 100%;
  position: relative;
  transition: all 0.2s ease;
  filter: ${(props) => (props.unavailable ? "grayscale(1)" : "none")};
  border: ${(props) => (props.selected ? `3px solid ${colors.red}` : "none")};
  img {
    object-fit: cover;
    height: 100%;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Copy = styled.p`
  font-family: "Poppins";
  color: ${colors.copy};
  font-size: 0.8rem;
  line-height: 1;
  margin-top: 8px;
  text-align: left;
  text-decoration: ${(props) => (props.selected ? "underline" : "none")};
`;
